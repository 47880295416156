import { Button, Text } from '@shared/components';
import {
  ConversationStatus,
  ConversationType,
} from '@shared/generated/graphql';
import { makeElementClassNameFactory, makeRootClassName } from '@shared/utils';
import clsx from 'clsx';
import { capitalize } from 'lodash';
import { useState } from 'react';
import {
  PiPhoneCallDuotone,
  PiPhoneDisconnectDuotone,
  PiPhoneOutgoingDuotone,
} from 'react-icons/pi';
import { ConversationFeedItem } from '../../types';
import { FeedItemTimestamp } from '../feed-item/FeedItem';

const ROOT = makeRootClassName('ConversationFeedItemPreview');
const el = makeElementClassNameFactory(ROOT);

type ConversationFeedItemPreviewProps = {
  item: ConversationFeedItem;
};

export const ConversationFeedItemPreview = ({
  item,
}: ConversationFeedItemPreviewProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className={el`conversation-root`}>
      <div className={el`conversation-header`}>
        <div className="flex flex-row items-center gap-2">
          <ConversationIcon status={item.data.conversation.status} />
          <Text type="body-sm" className="text-gray-700">
            {describeConversation(
              item.data.conversation.status,
              item.data.conversation.type
            )}
          </Text>
        </div>
        <FeedItemTimestamp timestamp={item.createdAt} />
      </div>
      <div className={el`conversation-body`}>
        <Text type="body-xs" className="text-gray-500">
          Goal: {item.data.conversation.purposeDescription}
        </Text>
        <Button
          variant="tertiary"
          size="xs"
          onPress={() => setIsExpanded((s) => !s)}
        >
          {`${isExpanded ? 'Hide' : 'View'} details`}
        </Button>
      </div>
      {isExpanded && (
        <ConversationDetails conversationId={item.data.conversation.id} />
      )}
    </div>
  );
};

const ConversationDetails = ({
  conversationId,
}: {
  conversationId: string;
}) => {
  // TODO(mike): Pull external messages or the full transcript to display here
  return (
    <div>
      <Text type="body-xs" className="text-gray-500">
        {conversationId} Lorem ipsum dolor sit amet, consectetur adipiscing
        elit.
      </Text>
    </div>
  );
};

const ConversationIcon = ({ status }: { status: ConversationStatus }) => {
  const className = clsx(el`conversation-icon`, {
    ACTIVE: status === ConversationStatus.ACTIVE,
  });
  switch (status) {
    case ConversationStatus.PENDING:
      return <PiPhoneOutgoingDuotone size={22} className={className} />;
    case ConversationStatus.ACTIVE:
      return <PiPhoneCallDuotone size={22} className={className} />;
    case ConversationStatus.COMPLETED:
      return <PiPhoneDisconnectDuotone size={22} className={className} />;
    default:
      return null;
  }
};

const describeConversation = (
  status: ConversationStatus,
  type: ConversationType
) => {
  const t = stringifyConversationType(type);
  switch (status) {
    case ConversationStatus.PENDING:
      return `Vooma is about to start a ${t}`;
    case ConversationStatus.ACTIVE:
      return `Vooma is on a ${t}`;
    case ConversationStatus.COMPLETED:
      return `Vooma completed a ${t}`;
    default:
      return capitalize(t);
  }
};

const stringifyConversationType = (type: ConversationType) => {
  switch (type) {
    case ConversationType.PHONE_CALL:
      return 'phone call';
    default:
      return 'conversation';
  }
};
